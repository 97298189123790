import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  private readonly prodStageUrl = 'td.adxad.com';

  initSentry() {
    if (environment.production && location.host === this.prodStageUrl) {
      Sentry.init({
        dsn: 'https://d9327687ea094edc86998ae1b27b8943@td.adxad.com/4',
        environment: 'production',
        tunnel: '/sntr',
        debug: false,
        release: '110.1'
      });
    }
  }
}
